@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































































































@import '@/assets/scss/_variables.scss';

.input-holder {
  margin: 0 0 10px;

  &--password-change {
    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }
}

.PasswordChange_root::v-deep {
  .TextFieldInput_label label {
    @media (min-width: $desktop-width) {
      font-size: 18px;
      line-height: 120%;
    }
  }
}

.PasswordChangePopupImg {
  height: 16px;
  margin: 0 0 25px 0;
}

.PasswordChangePopupDesc {
  font-size: 12px;
  line-height: 150%;
  margin: 0;

  span {
    color: #ec5656;
    text-transform: uppercase;
  }
}
.PasswordChangePopupButtons {
  display: flex;
  margin: 30px 0 0 0;

  button {
    height: 46px;
    width: 50%;
    font-size: 12px;

    &:first-child {
      margin: 0 5px 0 0;
    }

    &:last-child {
      margin: 0 0 0 5px;
    }
  }
}
