@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



















































.User__userpanel {
  padding: 21px 0;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  display: flex;
  justify-content: flex-start;
}

.User_change-password {
  margin: 20px 0;
  max-width: 420px;
}
